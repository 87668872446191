<template>
    <v-container fluid>
        <v-card class="mt-3">
            <Page-Header :title="$t('users.users')" icon="mdi-account-multiple">
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                class="pa-5"
                :headers="headers"
                :items="users"
                :search="search"
                :items-per-page="10"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" persistent max-width="800px">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-card>
                                <v-toolbar color="primary darken-1" dark>
                                    <v-card-title>
                                        <span class="">{{
                                            (editedIndex === -1
                                                ? $t("add")
                                                : $t("edit")) +
                                                " " +
                                                $t("users.user")
                                        }}</span>
                                    </v-card-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    persistent-hint
                                                    v-model="
                                                        editedItem.userName
                                                    "
                                                    :rules="[rules.required]"
                                                    :label="
                                                        $t('users.userName')
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    v-model="editedItem.email"
                                                    :rules="[
                                                        rules.required,
                                                        rules.email
                                                    ]"
                                                    :label="$t('email')"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                v-if="editedIndex == -1"
                                                cols="12"
                                                md="6"
                                            >
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    v-model="
                                                        editedItem.password
                                                    "
                                                    :label="
                                                        $t('users.password')
                                                    "
                                                    :rules="[rules.required]"
                                                    type="password"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                v-if="editedIndex == -1"
                                                cols="12"
                                                md="6"
                                            >
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    type="password"
                                                    v-model="
                                                        editedItem.confirmPassword
                                                    "
                                                    :rules="[
                                                        rules.required,
                                                        rules.confirmPassword
                                                    ]"
                                                    :label="
                                                        $t(
                                                            'users.confirmPassword'
                                                        )
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    v-model="
                                                        editedItem.phoneNumber
                                                    "
                                                    :label="$t('phone')"
                                                ></v-text-field> </v-col
                                            ><v-col cols="12" md="6">
                                                <v-select
                                                    outlined
                                                    dense
                                                    hide-details
                                                    :items="groups"
                                                    v-model="
                                                        editedItem.groupGuid
                                                    "
                                                    :label="
                                                        $t('groups.groupName')
                                                    "
                                                    item-text="name"
                                                    item-value="id"
                                                    :rules="[rules.required]"
                                                ></v-select>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                v-if="isSuperAdmin"
                                            >
                                                <v-select
                                                    outlined
                                                    dense
                                                    hide-details
                                                    :items="branches"
                                                    v-model="
                                                        editedItem.branchGuid
                                                    "
                                                    :label="
                                                        $t(
                                                            'branches.branchName'
                                                        )
                                                    "
                                                    item-text="branchName"
                                                    item-value="branchGuid"
                                                ></v-select>
                                            </v-col>

                                            <!-- <v-col cols="12" md="6">
                                                    <v-autocomplete
                                                        :items="doctors"
                                                        v-model="
                                                            editedItem.doctorGuid
                                                        "
                                                        :label="
                                                            $t(
                                                                'doctors.doctorName'
                                                            )
                                                        "
                                                        item-text="doctorName"
                                                        item-value="guid"
                                                    ></v-autocomplete>
                                                </v-col> -->
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="primary darken-1"
                                        class="white--text"
                                        @click="save"
                                        :disabled="!valid"
                                        :min-width="100"
                                        :loading="loading"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        :loading="loading"
                                        text
                                        color="red"
                                        @click="close"
                                    >
                                        {{ $t("cancel") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <confirm-dialog
                        :openDialog="dialogDelete"
                        :onClicked="deleteItemConfirm"
                        :onClose="closeDelete"
                    ></confirm-dialog>
                    <v-dialog
                        v-model="dialogChangePassword"
                        persistent
                        max-width="400px"
                    >
                        <v-form ref="form1" v-model="valid1" lazy-validation>
                            <v-card>
                                <v-toolbar color="primary darken-1" dark>
                                    <v-card-title>
                                        <span class="">
                                            {{
                                                $t("users.changePassword")
                                            }}</span
                                        >
                                    </v-card-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    hide-details
                                                    outlined
                                                    dense
                                                    v-model="
                                                        editedItem.password
                                                    "
                                                    :label="
                                                        $t('users.password')
                                                    "
                                                    :rules="[rules.required]"
                                                    type="password"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    hide-details
                                                    outlined
                                                    dense
                                                    type="password"
                                                    v-model="
                                                        editedItem.confirmPassword
                                                    "
                                                    :rules="[
                                                        rules.required,
                                                        rules.confirmPassword
                                                    ]"
                                                    :label="
                                                        $t(
                                                            'users.confirmPassword'
                                                        )
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="primary darken-1"
                                        class="white--text"
                                        @click="saveChangePassword"
                                        :disabled="!valid1"
                                        :min-width="100"
                                        :loading="loading"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        :loading="loading"
                                        color="red"
                                        @click="closeChangePassword"
                                    >
                                        {{ $t("cancel") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="text-end">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="isInRole(0)"
                                    icon
                                    :loading="loading"
                                    @click="changePassword(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>
                                        mdi-key
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("users.changePassword") }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="isInRole(13)"
                                    icon
                                    :loading="loading"
                                    class="mx-1"
                                    @click="editItem(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("edit") }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="isInRole(14)"
                                    color="red darken-2"
                                    icon
                                    :loading="loading"
                                    @click="deleteItem(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>
                                        mdi-delete-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("delete") }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            loading: true,
            valid: true,
            valid1: true,
            dialog: false,
            dialogDelete: false,
            dialogChangePassword: false,
            editedIndex: -1,
            search: "",
            headers: [
                { text: this.$t("users.userName"), value: "userName" },
                { text: this.$t("email"), value: "email" },
                { text: this.$t("phone"), value: "phoneNumber" },
                { text: this.$t("groups.groupName"), value: "groupName" },
                { text: "", value: "actions" }
            ],
            users: [],
            groups: [],
            branches: [],
            editedItem: {
                userName: "",
                email: null,
                password: "",
                confirmPassword: "",
                phoneNumber: null,
                userGroup: "",
                branchGuid: "",
                groupGuid: ""
            },
            defaultItem: {
                userName: "",
                email: null,
                password: "",
                confirmPassword: "",
                phoneNumber: null,
                userGroup: "",
                branchGuid: "",
                groupGuid: ""
            },
            isSuperAdmin: this.isInRole(1),
            rules: {
                required: value => !!value || this.$t("ThisFieldIsRequired"),
                confirmPassword: () =>
                    this.editedItem.confirmPassword ===
                        this.editedItem.password ||
                    this.$t("users.notMatchPassword"),
                email: v => /.+@.+\..+/.test(v) || this.$t("users.validEmail")
            }
        };
    },
    created() {
        this.loading = true;
        this.getData();

        axios.get("Accounts/GetGroups").then(response => {
            this.groups = response.data.data;
        });

        if (this.isSuperAdmin) {
            axios.get("Accounts/GetBranches").then(response => {
                this.branches = response.data.data;
            });
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);

            this.dialog = true;
        },
        changePassword(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);

            this.dialogChangePassword = true;
        },
        deleteItem(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.users[this.editedIndex];
            axios
                .delete("Accounts/DeleteUser?id=" + deleteItem.id)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }

                    this.getData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeChangePassword() {
            this.dialogChangePassword = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form1.reset();
            this.$refs.form1.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getData() {
            try {
                this.loading = true;
                axios
                    .get("Accounts/GetUsers")
                    .then(response => {
                        this.users = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        save() {
            var val = this.$refs.form.validate();
            if (val) {
                if (this.editedIndex > -1) {
                    var updatedItem = this.users[this.editedIndex];
                    updatedItem.userName = this.editedItem.userName;
                    updatedItem.email = this.editedItem.email;
                    updatedItem.phoneNumber = this.editedItem.phoneNumber;
                    updatedItem.branchGuid = this.editedItem.branchGuid;
                    updatedItem.groupGuid = this.editedItem.groupGuid;

                    axios
                        .post("Accounts/UpdateUser", updatedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.close();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }

                            axios.get("Accounts/GetGroups").then(response => {
                                this.groups = response.data.data;

                                this.getData();
                            });
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.getData();
                            console.log(e);
                        });
                    Object.assign(
                        this.users[this.editedIndex],
                        this.editedItem
                    );
                } else {
                    axios
                        .post("Accounts/AddUser", this.editedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.close();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }

                            axios.get("Accounts/GetGroups").then(response => {
                                this.groups = response.data.data;

                                this.getData();
                            });
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.getData();
                            console.log(e);
                        });
                }
            }
        },
        saveChangePassword() {
            var val = this.$refs.form1.validate();
            if (val) {
                var updatedItem = this.users[this.editedIndex];
                var changePasswordModel = {
                    id: updatedItem.id,
                    password: this.editedItem.password,
                    confirmPassword: this.editedItem.confirmPassword
                };
                axios
                    .post(
                        "Accounts/ChangeUserPasswordByAdmin",
                        changePasswordModel
                    )
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.closeChangePassword();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }

                        this.getData();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        this.getData();

                        console.log(e);
                    });
            }
        }
    }
};
</script>
