var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-3"},[_c('Page-Header',{attrs:{"title":_vm.$t('users.users'),"icon":"mdi-account-multiple"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary darken-1"},nativeOn:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1)]},proxy:true}])}),_c('v-data-table',{staticClass:"pa-5",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"items-per-page":10,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('v-card-title',[_c('span',{},[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("users.user")))])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","persistent-hint":"","rules":[_vm.rules.required],"label":_vm.$t('users.userName')},model:{value:(
                                                    _vm.editedItem.userName
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "userName", $$v)},expression:"\n                                                    editedItem.userName\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","rules":[
                                                    _vm.rules.required,
                                                    _vm.rules.email
                                                ],"label":_vm.$t('email')},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1),(_vm.editedIndex == -1)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('users.password'),"rules":[_vm.rules.required],"type":"password"},model:{value:(
                                                    _vm.editedItem.password
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "password", $$v)},expression:"\n                                                    editedItem.password\n                                                "}})],1):_vm._e(),(_vm.editedIndex == -1)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","type":"password","rules":[
                                                    _vm.rules.required,
                                                    _vm.rules.confirmPassword
                                                ],"label":_vm.$t(
                                                        'users.confirmPassword'
                                                    )},model:{value:(
                                                    _vm.editedItem.confirmPassword
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "confirmPassword", $$v)},expression:"\n                                                    editedItem.confirmPassword\n                                                "}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t('phone')},model:{value:(
                                                    _vm.editedItem.phoneNumber
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "phoneNumber", $$v)},expression:"\n                                                    editedItem.phoneNumber\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","items":_vm.groups,"label":_vm.$t('groups.groupName'),"item-text":"name","item-value":"id","rules":[_vm.rules.required]},model:{value:(
                                                    _vm.editedItem.groupGuid
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "groupGuid", $$v)},expression:"\n                                                    editedItem.groupGuid\n                                                "}})],1),(_vm.isSuperAdmin)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","items":_vm.branches,"label":_vm.$t(
                                                        'branches.branchName'
                                                    ),"item-text":"branchName","item-value":"branchGuid"},model:{value:(
                                                    _vm.editedItem.branchGuid
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "branchGuid", $$v)},expression:"\n                                                    editedItem.branchGuid\n                                                "}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary darken-1","disabled":!_vm.valid,"min-width":100,"loading":_vm.loading},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"text":"","color":"red"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialogChangePassword),callback:function ($$v) {_vm.dialogChangePassword=$$v},expression:"dialogChangePassword"}},[_c('v-form',{ref:"form1",attrs:{"lazy-validation":""},model:{value:(_vm.valid1),callback:function ($$v) {_vm.valid1=$$v},expression:"valid1"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('v-card-title',[_c('span',{},[_vm._v(" "+_vm._s(_vm.$t("users.changePassword")))])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","label":_vm.$t('users.password'),"rules":[_vm.rules.required],"type":"password"},model:{value:(
                                                    _vm.editedItem.password
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "password", $$v)},expression:"\n                                                    editedItem.password\n                                                "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","type":"password","rules":[
                                                    _vm.rules.required,
                                                    _vm.rules.confirmPassword
                                                ],"label":_vm.$t(
                                                        'users.confirmPassword'
                                                    )},model:{value:(
                                                    _vm.editedItem.confirmPassword
                                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "confirmPassword", $$v)},expression:"\n                                                    editedItem.confirmPassword\n                                                "}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary darken-1","disabled":!_vm.valid1,"min-width":100,"loading":_vm.loading},on:{"click":_vm.saveChangePassword}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","loading":_vm.loading,"color":"red"},on:{"click":_vm.closeChangePassword}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                                                var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [(_vm.isInRole(0))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.changePassword(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-key ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("users.changePassword")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [(_vm.isInRole(13))?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [(_vm.isInRole(14))?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red darken-2","icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }